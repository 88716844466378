import Header from './components/basic/Header';
import Footer from './components/Footer';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Home from './components/pages/Home';
import Nav from './components/Nav'
import Leg from './components/pages/Leg';
import Result from './components/pages/Result';
import './css/index.scss';

function App() {
  return (
    <div className="App">
      <Header />
      <BrowserRouter>
        <Nav />
        <main>
          <Switch>
            <Route path="/" component={Home} exact />
            <Route path="/about" component={Home} />            
            <Route path="/results" component={Result} />            
            <Route path="/legs" component={Leg} />
          </Switch>
        </main>
      </BrowserRouter>

      <Footer />
    </div>
  );
}

export default App;
