import {  NavLink } from 'react-router-dom';


function Nav() {
    return (
        <nav>
            <div className='d-flex flex-row-reverse subHeading p-2'>
                <ul className="nav navCust">
                        <li className="nav-item"><NavLink to="/legs">Legs</NavLink> </li>
                        <li className="nav-item"><NavLink to="/results">Results</NavLink> </li>
                        <li className="nav-item"><NavLink to="/">Home</NavLink></li>
                </ul>
            </div>
        </nav>
    );
}
export default Nav;

