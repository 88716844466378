
function Box(props) {
    return (
        <div className="col-sm-5 custCol p-4 m-2">
            <h2>{props.header}</h2>
            <p> {props.text}</p>
        </div>
    );
}

export default Box;