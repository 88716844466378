import { forwardRef } from "react";
import MaterialTable from "material-table";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { ArrowDownward, ChevronLeft, ChevronRight } from "@material-ui/icons";
import Clear from "@material-ui/icons/Clear";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
export default function CustomTable(props) {
  const tableIcons = {
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };

  if (Array.isArray(props.data) && Array.isArray(props.columns)) {
    console.log(props.data);
    debugger;
    return (
      <div className="col-md-12 justify-content-center">
        <MaterialTable
          icons={tableIcons}
          data={props.data}
          columns={props.columns}
          title={props.title}
          options={{
            exportButton: true,
            pageSizeOptions: [5,10,20,30,
              50,
              100,
              { value: props.data.length, label: "All" },
            ],
            headerStyle: {
              backgroundColor: "#5d8c8c",
              color: "#FFF",
              "font-family": '"Syne Mono", monospace',
              "text-transform": "capitalize",
            },
            grouping: true,
            search: false,
          }}
        />
      </div>
    );
  } else {
    return (
      <div className="col-md-12">
        <Loader type="Bars" color="#f2388f" height={100} width={100} />
      </div>
    );
  }
}
