
import { SocialIcon } from 'react-social-icons';

const highlightCol = "#F2388F";
function Footer() {
    return (
        <footer>
            <div className="footer row">
                <div className='col'>
                    <SocialIcon network="github" bgColor={highlightCol} url="https://github.com/cscheerio" />
                    <SocialIcon network="twitter" bgColor={highlightCol} url="https://twitter.com/chelsearcheerio" />
                    <SocialIcon network="linkedin" bgColor={highlightCol} url="https://www.linkedin.com/in/chelsea-hardy/" />
                </div>
            </div>
        </footer>
    );
}

export default Footer;
