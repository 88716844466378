import React, { useState, useEffect } from "react";
import axios from "axios";
import CustomTable from "../ResultTable";

function Result(props) {
  const [results, getResults] = useState("");
  const [columns, getColumns] = useState("");

  useEffect(() => {
    getAllResults(2016);
  }, []);

  const getAllResults = (year) => {
    return fetch(
      "/backend/api/result/read.php?year=" + year
    ).then(
      axios
        .get("backend/api/result/read.php?year=" + year)
        .then((response) => {
          const allResults = response.data.records;
          getResults(allResults);
          const columns = [];
          for (var key in allResults[0]) {
            if (key !== "racer") {
              columns.push({
                field: key,
                title: key.replace(new RegExp("_", "g"), " "),
              });
            }
          }
          getColumns(columns);
        })
        .catch((e) => {
          console.log(e);
        })
    );
  };
  return (
    <CustomTable data={results} columns={columns} title="Results" />
  );
}

export default Result;
