import Box from "../basic/Box";

function Home() {
  return (
    <>
      <div className="article row justify-content-center">
        <Box header="About" text="This is a simple php api project that accesses leg information from 
         a mysql database and displays in a series of boostrap collapse elements. The results page 
         is a data extraction from race results into a material table component. The results are from 
         2014-2016. The table control allows sorting, grouping, and optional display of the number of rows." />

      </div>
    </>
  );
}

export default Home;
