import { Accordion, Card } from "react-bootstrap";
import Countdown from 'react-countdown';
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

export default function LegDisplay(props) {
  const colClass = "col p-2";
  const displayLegs = (props) => {
    const legs = props;
    if (Array.isArray(legs.legs.records)) {
      const records = legs.legs.records;
      console.log(records);

      return records.map((leg, id) => {
        console.log(leg);
        const mustStart = new Date(leg.must_start);
        const cutoffDate = new Date(leg.cutoff);
        return (
          <div key={id} className="col-md-12 ">
            <Card>
              <Accordion.Toggle as={Card.Header} eventKey={leg.id}>
               <h2> {leg.name} </h2>
                 <div>Must Start Remaining: <Countdown date={mustStart} /> </div>
                 <div>Cut Off: <Countdown date={cutoffDate} /></div>
              </Accordion.Toggle>

              <Accordion.Collapse eventKey={leg.id}>
                <Card.Body>
                  <div className="row">
                    <div className={colClass}>Leg Number</div>
                    <div className={colClass}>{leg.id}</div>
                  </div>
                  <div className="row">
                    <div className={colClass}>Leg Distance</div>
                    <div className={colClass}>{leg.distance}</div>
                  </div>
                  <div className="row">
                    <div className={colClass}>Leg Gain</div>
                    <div className={colClass}>{leg.gain}</div>
                  </div>
                  <div className="row">
                    <div className={colClass}>Leg Loss</div>
                    <div className={colClass}>{leg.loss}</div>
                  </div>
                  <div className="row">
                    <div className={colClass}>Leg Max Elevation</div>
                    <div className={colClass}>{leg.max_elevation}</div>
                  </div>
                  <div className="row">
                    <div className={colClass}>Leg Check Points</div>
                    <div className={colClass}>{leg.check_points}</div>
                  </div>
                  <div className="row">
                    <div className={colClass}>Leg Trail Type</div>
                    <div className={colClass}>{leg.trail_type}</div>
                  </div>
                  <div className="row">
                    <div className={colClass}>Leg Trail Type</div>
                    <div className={colClass}>{leg.trail_type}</div>
                  </div>
                  <div>
                    
                  </div>
                  <div className="row">
                    <div className={colClass}>Leg Trail Type</div>
                    <div className={colClass}>{leg.must_start}</div>
                  </div>
                  <div className="row">
                    <div className={colClass}>Leg Cutoff</div>
                    <div className={colClass}>{leg.cutoff}</div>
                  </div>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </div>
        );
      });
    } else {
      return (<div className="col-md-12">
      <Loader type="Bars" color="#f2388f" height={100} width={100} />
    </div>);
    }
  };

  return <>{displayLegs(props)}</>;
}

