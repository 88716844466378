import React, { useEffect, useState } from "react";
import axios from "axios";
import LegDisplay from "../LegTable";
import {Accordion} from 'react-bootstrap';

function Leg() {
  const [legs, getLegs] = useState("");

  useEffect(() => {
    getAllLegs();
  }, []);

  const getAllLegs = () => {
    return fetch("/backend/api/leg/read.php").then(
      axios
        .get("backend/api/leg/read.php")
        .then((response) => {
          const allLegs = response.data;
          getLegs(allLegs);
        })
        .catch((e) => {
          console.log(e);
        })
    );
  };
  return (<Accordion><LegDisplay legs={legs} /></Accordion>);
}

export default Leg;
